var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "letter-container",
    [
      _c("letter-header"),
      _c("div", [
        _c("div", {}, [_vm._v("MEMORANDUM IN LIEU OF ORDERS")]),
        _c("div", { staticClass: "flex mt-4" }, [
          _c("div", { staticClass: "mr-4" }, [_vm._v("FROM:")]),
          _c("div", {}, [
            _c("div", {}, [_vm._v("340 FTG/UFT")]),
            _c("div", {}, [_vm._v("375 J Street West, Ste 1")]),
            _c("div", {}, [_vm._v("JBSA-Randolph TX 78150")]),
          ]),
        ]),
        _c("div", { staticClass: "flex mt-4" }, [
          _vm._v(
            " SUBJECT: Letter In Lieu Of PCS Orders for " +
              _vm._s(_vm.candidateName) +
              ". "
          ),
        ]),
        _c("div", { staticClass: "mt-4" }, [
          _vm._v(
            " 1: This request is for TMO purposes only. Please accept this letter as authorization to use the 340th Flying Training Group fund cite for " +
              _vm._s(_vm.candidateName) +
              ". Full HHG shipment from "
          ),
          _c(
            "span",
            { staticClass: "font-bold", attrs: { "data-cy": "fromLocation" } },
            [_vm._v(_vm._s(_vm.fromLocation))]
          ),
          _vm._v(" to "),
          _c(
            "span",
            { staticClass: "font-bold", attrs: { "data-cy": "toLocation" } },
            [_vm._v(_vm._s(_vm.toLocation))]
          ),
          _vm._v(". Member will depart on "),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(_vm._s(_vm._f("formatDate")(_vm.dateDeparting))),
          ]),
          _vm._v(" will return "),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(_vm._s(_vm._f("formatDate")(_vm.dateReturning))),
          ]),
          _vm._v(". "),
        ]),
        _c("div", { staticClass: "mt-4" }, [
          _vm._v(" Number of dependents: "),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(_vm._s(_vm.numberOfDependents)),
          ]),
          _vm._v(". Number of vehicles: "),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(_vm._s(_vm.numberOfVehicles)),
          ]),
        ]),
        _c("div", { staticClass: "mt-4" }, [
          _vm._v(
            " 2: " +
              _vm._s(_vm.candidateName) +
              " is under administrative control of the 340th Flying Training Group, Undergraduate Flying Training flight. Member has acknowledged that additional costs incurred will be paid by member for transportation to another point required if the new PCS named in the order is different than that named in a statement prescribed in par 2.5.5 and 2.5.5.2 of the JFTR (Joint Federal Travel Regulations). "
          ),
        ]),
        _c("div", { staticClass: "mt-4" }, [
          _vm._v(
            " 3: The PCS order will be issued once all mandatory requirements have been met. If for any reason member does not report to dass, member will be responsible for the cost of the shipment. "
          ),
        ]),
        _c("div", { staticClass: "mt-4" }, [
          _vm._v(" 4: The fund cite: "),
          _c("span", { staticClass: "font-bold" }, [
            _vm._v(_vm._s(_vm.fundCite)),
          ]),
          _vm._v(". "),
        ]),
        _c("div", { staticClass: "mt-4" }, [
          _vm._v(
            " 5: For questions, please contact us at 210-652-6107, or via email 340FTG.UFT.Workflow@us.af.mil "
          ),
        ]),
      ]),
      _c("letter-footer"),
      _c("div", { staticClass: "mt-4" }, [_vm._v("TO: JPPSO")]),
      _c("div", { staticClass: "mt-4" }, [
        _vm._v(
          " I acknowledge receipt and understand my responsibility of paying any costs incurred for the shipment/storage of my household goods if my PCS orders are not subsequently issued or cancelled to authorize my reassignment move under the JTP Vol 1, Chapter 5, Part D, Para U5330. "
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }