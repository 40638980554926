
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import LetterContainer from '@/components/Letter/LetterContainerComponent.vue';
import LetterHeader from '@/components/Letter/LetterHeaderComponent.vue';
import LetterFooter from '@/components/Letter/LetterFooterComponent.vue';
import { formatDate } from '@/services/formatService';

@Component<TrafficManagementLetterPage>({
  components: { LetterContainer, LetterFooter, LetterHeader },
  filters: {
    formatDate(date: Date) {
      return formatDate(date);
    }
  }
})
export default class TrafficManagementLetterPage extends Vue {
  @Prop({ default: () => new Date() })
  dateDeparting!: Date;

  @Prop({ default: 0 })
  numberOfTravelDays!: number;

  @Prop({ default: 0 })
  numberOfDependents!: number;

  @Prop({ default: 0 })
  numberOfVehicles!: number;

  @Prop({ default: '57237 5026234 3005T1 72617 58162F 01 387700' })
  fundCite!: string;

  @Prop({ default: 'here' })
  fromLocation!: string;

  @Prop({ default: 'there' })
  toLocation!: string;

  @Prop({ default: '2LT Sanders, Edward' })
  candidateName!: string;

  get dateReturning(): Date {
    const returnDate = new Date(this.dateDeparting);
    returnDate.setDate(returnDate.getDate() + this.numberOfTravelDays);
    return returnDate;
  }
}
